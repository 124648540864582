<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>新增打卡紀錄</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="add-checkin-form" @submit.prevent="formSubmit">
          <v-card class="mb-8 px-6 py-8">
            <v-row>
              <v-col md="6" cols="12">
                <FormControl
                  inputType="select"
                  :inputValue.sync="staffID"
                  label="員工"
                  :options="staffList"
                  :required="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4>上班打卡</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <FormControl
                  inputType="datePicker"
                  :inputValue.sync="checkInDate"
                  label="上班日期"
                  :required="true"
                  :showClearBtn="false"
                />
              </v-col>
              <v-col md="6" cols="12">
                <FormControl inputType="timePicker" :inputValue.sync="checkInTime" label="上班時間" :required="true" />
              </v-col>
              <v-col md="6" cols="12">
                <FormControl
                  inputType="select"
                  :inputValue.sync="checkInMethod"
                  label="打卡類型"
                  :options="checkInMethodList"
                  :required="true"
                />
              </v-col>
              <v-col md="6" cols="12" v-if="checkInMethod === 'image'">
                <ImageUploader
                  :singleImage="true"
                  :imgList.sync="checkInImg"
                  :uploadText="'上載打卡證明'"
                  :acceptFormat="'image'"
                  :disableUpload="submitLoading"
                ></ImageUploader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4>下班打卡</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <FormControl
                  inputType="datePicker"
                  :inputValue.sync="checkOutDate"
                  label="下班日期"
                  :required="true"
                  :minDay="checkInDate"
                  :showClearBtn="false"
                />
              </v-col>
              <v-col md="6" cols="12">
                <FormControl inputType="timePicker" :inputValue.sync="checkOutTime" label="下班時間" :required="true" />
              </v-col>
              <v-col md="6" cols="12">
                <FormControl
                  inputType="select"
                  :inputValue.sync="checkoutMethod"
                  label="打卡類型"
                  :options="checkInMethodList"
                  :required="true"
                />
              </v-col>
              <v-col md="6" cols="12" v-if="checkoutMethod === 'image'">
                <ImageUploader
                  :singleImage="true"
                  :imgList.sync="checkOutImg"
                  :uploadText="'上載打卡證明'"
                  :acceptFormat="'image'"
                  :disableUpload="submitLoading"
                ></ImageUploader>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col cols="12">
              <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="submitLoading">
                儲存
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'AddCheckIn',
  components: {
    FormControl,
    ImageUploader,
  },
  data() {
    return {
      submitLoading: false,
      submitSuccess: false,
      staffList: [],
      checkInMethodList: [
        { text: 'GPS', value: 'gps' },
        { text: '普通', value: 'image' },
      ],

      staffID: '',
      checkInDate: '',
      checkInTime: '',
      checkOutDate: '',
      checkOutTime: '',
      checkInMethod: '',
      checkoutMethod: '',
      checkInImg: '',
      checkOutImg: '',
    }
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getStaffList() {
      try {
        const payload = { verify_token: this.getAdminToken() }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff -----')
        this.$func.log(result)

        for (let i = 0; i < result.data.length; i++) {
          this.staffList.push({
            text: `${result.data[i].name} (${result.data[i].email})`,
            value: result.data[i].id,
          })
        }
      } catch (error) {
        this.$func.log('-----Get Staff Fail-----')
        this.$func.log(error)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    async formSubmit() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const isValid = this.$refs['add-checkin-form'].validate()

        let message = ''
        if (!isValid) {
          message = '請先填滿所有必填項目'
        } else {
          if (this.checkInDate > this.checkOutDate) {
            message = '上班日期不可晚於下班日期'
          } else if (this.checkInDate === this.checkOutDate) {
            if (this.checkInTime > this.checkOutTime) {
              message = '上班日期不可晚於下班時間'
            }
          }
        }

        if (this.$validate.DataValid(message)) {
          this.submitLoading = false

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: message,
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            data_type: 'check_in_data',
            status: 'active',
            data_status: 'active',
            verify_token: this.getAdminToken(),
            staff_id: parseInt(this.staffID),
            checkin_date: this.checkInDate,
            checkin_time: this.checkInTime,
            checkout_date: this.checkOutDate,
            checkout_time: this.checkOutTime,
            checkin_method: this.checkInMethod,
            checkout_method: this.checkoutMethod,
            checkin_proof_image: this.checkInMethod === 'image' ? this.checkInImg : '',
            checkout_proof_image: this.checkoutMethod === 'image' ? this.checkOutImg : '',
          }

          const result = await this.$XHR.api('cms_add_checkin_checkout_record', payload)
          this.$func.log('--- add check in record ---')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '新增成功',
            isError: false,
            returnLink: { name: 'CheckInList' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('--- add check in record fail ---')
          this.$func.log(error)

          let msg = '新增失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'staff not found') {
            msg = '員工不存在'
          } else if (error.data === 'checkin date cannot be empty') {
            msg = '上班日期不可為空'
          } else if (error.data === 'checkin time cannot be empty') {
            msg = '上班時間不可為空'
          } else if (error.data === 'checkout date cannot be empty') {
            msg = '下班日期不可為空'
          } else if (error.data === 'checkout time cannot be empty') {
            msg = '下班時間不可為空'
          } else if (error.data === 'checkout date cannot earlier than check in date') {
            msg = '上班日期不可晚於下班日期'
          } else if (error.data === 'checkout time cannot earlier than check in time') {
            msg = '上班日期不可晚於下班時間'
          } else if (error.data === 'check in / check out datetime overlap with other record') {
            msg = '打卡日期/時間不可與其他打卡紀錄重疊'
          }

          this.submitLoading = false
          this.submitSuccess = false
          this.setDialogMessage({
            message: msg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  watch: {
    checkInDate: {
      handler(val) {
        this.checkOutDate = ''
        this.checkOutTime = ''
      },
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getStaffList()
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (!this.submitSuccess) {
      if (this.$validate.DataValid(this.checkInImg)) {
        removeList.push(this.checkInImg)
      }

      if (this.$validate.DataValid(this.checkOutImg)) {
        removeList.push(this.checkOutImg)
      }
    } else {
      if (this.checkInMethod === 'gps') {
        if (this.$validate.DataValid(this.checkInImg)) {
          removeList.push(this.checkInImg)
        }
      }

      if (this.checkoutMethod === 'gps') {
        if (this.$validate.DataValid(this.checkOutImg)) {
          removeList.push(this.checkOutImg)
        }
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }
  },
}
</script>
